import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, makeStateKey, TransferState, inject } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { startWith, map, tap } from 'rxjs/operators';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { Database, equalTo, get, limitToFirst, listVal, objectVal, orderByChild, query, ref } from '@angular/fire/database';

@Injectable()
export class IFirebaseService {
	private readonly isServer = isPlatformServer(this.platformId);
	private readonly isBrowser = isPlatformBrowser(this.platformId);

	private firebaseDataBaseUrl = '';

	public db = inject(Database);

	constructor(private transferState: TransferState, private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object, private configService: ConfigService) {
		// this.firebaseDataBaseUrl = this.configService.getSettings('firebase.databaseURL', '');
		this.firebaseDataBaseUrl = this.configService.getSettings('endpoints.defaults.base', '');
	}

	/**
	 *
	 * @param listPath
	 * @param orderBy
	 * @param filterFieldValue
	 * @param limit
	 * @private
	 */
	public _getDataFromFirebaseWithFilter(listPath: string, orderBy: string, filterFieldValue: any, limit: number | null): Observable<any[]> {
		const firebaseState = makeStateKey<any>('_getDataFromFirebase-' + listPath + orderBy + (filterFieldValue ? filterFieldValue.toString() : '') + limit.toString());
		if (this.isServer) {
			let params = new HttpParams();
			params = params.append('donotcache', 'true');
			if (orderBy) {
				params = params.append('orderBy', `"${orderBy}"`);

				if (filterFieldValue) {
					if (isNaN(filterFieldValue)) {
						params = params.append('equalTo', `"${filterFieldValue}"`);
					} else {
						params = params.append('equalTo', filterFieldValue);
					}
				}

				if (limit && Number(limit) > 0) {
					params = params.append('limitToFirst', limit.toString(10));
				}
			}

			if (!listPath.startsWith('/')) {
				listPath = `/${listPath}`;
			}
			const completePath = `${this.firebaseDataBaseUrl}${listPath}.json`;
			return this.http.get<any>(completePath, { params: params }).pipe(
				map((payload) => {
					if (!!payload && typeof payload === 'object') {
						return Object.keys(payload).map((key) => {
							return payload[key];
						});
					} else {
						return payload;
					}
				}),

				// since the REST API only returns a single JSON object ordering is not guarenteed and we have to sort ourselves again on the server :/
				map((payload: any[]) => {
					if (orderBy && typeof payload === 'object') {
						return payload.sort((a, b) => {
							return a[orderBy] > b[orderBy] ? 1 : -1;
						});
					}

					return payload;
				}),
				tap((payload) => {
					this.transferState.set(firebaseState, payload);
				})
			);
		}

		let filteredData = [];
		if (orderBy && !filterFieldValue) {
			filteredData = [orderByChild(orderBy)];
		}
		if (orderBy && filterFieldValue) {
			filteredData = [orderByChild(orderBy), equalTo(filterFieldValue)];
		}

		if (limit && Number(limit) > 0) {
			filteredData.push(limitToFirst(Number(limit)));
		}

		const dbRef = ref(this.db, listPath);
		const listWithQuery = query(dbRef, ...filteredData);

		const startsWith = this.transferState.get(firebaseState, undefined);
		return listVal(listWithQuery).pipe(this.isBrowser && startsWith ? startWith(startsWith) : tap());
	}

	/**
	 *
	 * @param listPath
	 * @private
	 */
	public _getDataFromFirebase(listPath: string): Observable<any> {
		const firebaseState = makeStateKey<any>('_getDataFromFirebase-' + listPath);
		if (this.isServer) {
			if (!listPath.startsWith('/')) {
				listPath = `/${listPath}`;
			}
			const completePath = `${this.firebaseDataBaseUrl}${listPath}.json`;
			return this.http.get(`${completePath}?donotcache`).pipe(
				tap((payload) => {
					this.transferState.set(firebaseState, payload);
				})
			);
		}

		const startsWith = this.transferState.get(firebaseState, undefined);
		return objectVal(ref(this.db, listPath)).pipe(this.isBrowser && startsWith ? startWith(startsWith) : tap());
	}

	public handleError(data) {
		return throwError(data);
	}
}
