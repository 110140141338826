import { CommonModule, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { getIdentify, onCredentialsValidationFailed, onLoginRequestedByExperience, setCredentials } from '@monterosa-sdk/identify-kit';
import { embed, Experience, getExperience, onReady, unmount } from '@monterosa-sdk/launcher-kit';
import { Unsubscribe } from '@monterosa-sdk/util';
import { TranslateModule } from '@ngx-translate/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { WINDOW } from '@nx-bundesliga/bundesliga-com/framework/window';
import { MonterosaService } from '@nx-bundesliga/bundesliga-com/services/common';
import { CommonsLibModule } from '@nx-bundesliga/commons';
import { BundesligaEmbedMonterosa } from '@nx-bundesliga/models';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { combineLatestWith, distinctUntilChanged, ReplaySubject, Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

/**
 * Embed Monterosa Component
 * Expected Output: BundesligaEmbedMonterosa
 * Description:
 * 		This component is used to embed Monterosa experience in the page.
 * 		It takes BundesligaEmbedMonterosa as input and renders the Monterosa experience in the page.
 * 			1. If the channelsEnabled.mobile is true and isWebview is true, then it will render the Monterosa experience in the webview.
 * 			2. If the channelsEnabled.web is true and isWebview is false, then it will render the Monterosa experience in the web.
 * 			3. If the channelsEnabled.mobile and channelsEnabled.web are false, then it will not render the Monterosa experience.
 * 			4. If the eventId is not provided, then it will not render the Monterosa experience.
 * 			5. If the oktaWebViewAccessToken is provided, then it will set the credentials for the Monterosa experience.
 * Example URL:
 * 		http://localhost:4000/de/liveticker/DFL-COM-000001/DFL-SEA-0001K8/DFL-DAY-004C69/DFL-MAT-J04034?showMonterosa=true
 *
 * How to get an example token:
 * 		1. Open the browser console from this url:
 * 			'https://bundesliga-dev.com'
 * 		2. Login to your account
 * 		3. Run the following command in the console:
 * 			console.log(`window.postMessage({ token: '${JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken}' }, '*');`);
 * 		4. Copy the token and use it in the oktaWebViewAccessToken input
 */

interface WebViewData {
	news?: {
		url: string;
	};
	liveticker?: {
		competitionId: string;
		seasonId: string;
		matchdayId: string;
		matchId: string;
	};
}

@Component({
	selector: 'bundesliga-embed-monterosa',
	standalone: true,
	templateUrl: './embed-monterosa.component.html',
	styleUrls: ['./embed-monterosa.component.scss'],
	imports: [CommonModule, NgOptimizedImage, TranslateModule, CommonsLibModule],
	changeDetection: ChangeDetectionStrategy.Default
})
export class EmbedMonterosaComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() set embed(value: BundesligaEmbedMonterosa) {
		this.monterosa = value;
		this.showMonterosa = this.monterosa && ((this.monterosa?.channelsEnabled?.mobile && this.isWebview) || (this.monterosa?.channelsEnabled?.web && !this.isWebview));
		this.oktaWebViewAccessToken$.next(this.monterosaService.getToken());
		this.eventId$.next(value?.eventId);
	}
	@Input() language = 'en';

	private experience: Experience;
	public monterosa: BundesligaEmbedMonterosa;
	public showMonterosa = false;
	public isBrowser = false;
	public isWebview = false;
	public experienceLoaded = false;
	public isNewsPage = false;
	private eventId: string;
	private monterosaListener: Array<Unsubscribe> = [];
	private webViewUrlObject: WebViewData;
	private embedSubscription: Subscription;
	private readonly eventId$ = new ReplaySubject<string>(1);
	public readonly oktaWebViewAccessToken$ = new ReplaySubject<string | null>(1);

	public readonly instanceId = uuidv4();
	constructor(@Inject(PLATFORM_ID) private readonly platformId: Object, private readonly config: ConfigService, @Inject(WINDOW) readonly window: Window, private monterosaService: MonterosaService, private router: Router) {
		this.isBrowser = isPlatformBrowser(this.platformId);
		this.isWebview = this.config.getSettings('webview', false);
	}

	ngOnInit(): void {
		this.window.addEventListener('message', this.handleMessageEvent);
		this.webViewUrlObject = this.parseWebViewUrl();
	}
	/**
	 * Load Monterosa Experience: Load Monterosa experience in the page
	 * if the user is logged in and the showMonterosa is true and the eventId is provided.
	 */
	ngAfterViewInit(): void {
		this.embedSubscription = this.eventId$.pipe(distinctUntilChanged(), combineLatestWith(this.oktaWebViewAccessToken$.pipe(distinctUntilChanged()))).subscribe({
			next: ([eventId, token]) => {
				if (eventId && this.isBrowser && this.showMonterosa) {
					if (token) {
						this.monterosaService.setToken(token);
					}

					if (this.eventId !== eventId) {
						this.eventId = eventId;
						if (this.experience) {
							this.removeAllListeners();
							unmount(this.instanceId);
							this.experienceLoaded = false;
						}

						const identify = getIdentify();
						this.experience = getExperience({
							eventId: eventId,
							autoresizesHeight: true,
							parameters: { lang: this.language === 'de' ? 'de' : 'en' }
						});
						embed(this.experience, this.instanceId);

						this.monterosaListener.push(
							onReady(this.experience, () => {
								const _token = this.monterosaService.getToken() || token;
								if (_token) {
									this.sendTokenToMonterosa(_token);
								} else {
									this.sendMessageToApp('webViewFinishedLoading');
								}
								this.experienceLoaded = true;
							})
						);

						this.monterosaListener.push(
							onCredentialsValidationFailed(identify, (error) => {
								console.warn('Monterosa Credentials validation failed', error);
								this.sendMessageToApp('webViewFinishedLoading');
							})
						);

						this.monterosaListener.push(onLoginRequestedByExperience(identify, () => this.sendMessageToApp('openSignUpScreen')));
					}

					if (this.experienceLoaded && this.experience && token) {
						this.sendTokenToMonterosa(token);
					}
				} else {
					console.warn('Missing required parameters for Monterosa initialization:', {
						eventId,
						token
					});
				}
			},
			error: (err) => {
				console.error('Error while subscribing to Monterosa parameters:', err);
			}
		});
	}

	ngOnDestroy(): void {
		this.removeAllListeners();
		this.window.removeEventListener('message', this.handleMessageEvent);
		unmount(this.instanceId);
		this.embedSubscription?.unsubscribe();
	}

	private handleMessageEvent = (event: MessageEvent): void => {
		const token = event.data?.token;
		if (token) {
			this.oktaWebViewAccessToken$.next(token);
		}
	};

	private sendTokenToMonterosa(token: string): void {
		const identify = getIdentify();
		// todo: remove this timeout when monterosa fixed race condition issue on their side
		// wait here for 0.5 second to make sure the experience is ready to receive the token

		setCredentials(identify, { token: token });
	}

	/**
	 * Remove all listeners: Remove all the listeners from the monterosa experience
	 */
	private removeAllListeners(): void {
		this.monterosaListener.forEach((listener) => listener());
		this.monterosaListener = [];
	}

	/**
	 * Open sign up screens for android and ios webviews
	 * @returns void
	 */
	private sendMessageToApp(name: any): void {
		if (this.window['dflWebviewEvent']) {
			//Todo - track here that android sign-up screen was called
			// Call Android interface
			this.window['dflWebviewEvent'].logEvent(name, JSON.stringify(this.webViewUrlObject));
		} else if (this.window.webkit && this.window.webkit.messageHandlers && this.window.webkit.messageHandlers['dflWebviewEvent']) {
			const message = {
				name: name,
				paramters: {}
			};
			this.window.webkit.messageHandlers['dflWebviewEvent'].postMessage(message);
		} else {
			// todo handle register flow  or token exchange for not webview cases
		}
	}

	private parseWebViewUrl(): WebViewData | null {
		const url = this.router.url;
		// Check if URL contains /liveticker/
		if (url.includes('/news/') || url.includes('/editorial/')) {
			this.isNewsPage = true;
			return {
				news: {
					url: url
				}
			};
		}
		if (url.includes('/liveticker/')) {
			try {
				//remove the query params from the url
				const urlWithoutQueryParams = url.split('?')[0];
				// Split the URL by '/' and get the parts after 'liveticker'
				const parts = urlWithoutQueryParams.split('/liveticker/')[1].split('/');

				// Ensure we have all required parts
				if (parts.length < 4) {
					console.error('Invalid webview URL:', url);
					return null;
				}

				return {
					liveticker: {
						competitionId: parts[0],
						seasonId: parts[1],
						matchdayId: parts[2],
						matchId: parts[3]
					}
				};
			} catch (error) {
				console.error('Error while parsing webview URL:', error);
			}
		}
		return null;
	}
}
