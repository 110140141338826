import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LiveBlogAutoEvents, LiveBlogClubs, LiveBlogEntryEditorialEmbedMonterosa, LiveBlogMatch } from '@nx-bundesliga/models';

@Component({
	selector: 'liveticker-event',
	templateUrl: './liveticker-event.component.html',
	styleUrls: ['./liveticker-event.component.scss'],
	animations: [trigger('blockInitialRenderAnimation', [transition(':enter', [])]), trigger('updateNotification', [state('visible', style([{ transform: 'translateY(0)' }])), transition('void => visible', [style({ transform: 'translateY(200%)' }), animate('600ms cubic-bezier(0.68, -0.55, 0.265, 1.55)')])])]
})
export class LivetickerEventComponent implements OnChanges {
	@Input() liveEntry: LiveBlogAutoEvents;
	@Input() match: LiveBlogMatch;
	@Input() teams: LiveBlogClubs;

	@Input() enableCardEvents = true;
	@Input() enableGoalEvents = true;
	@Input() enableSubstitutionEvents = true;
	@Input() enableBasicEvents = true;
	@Input() enableImageEvents = true;
	@Input() enableVideoEvents = true;
	@Input() enableEmbedEvents = true;
	@Input() enableProgressEvents = true;
	@Input() enableVarEvents = true;
	@Input() enableStatsEvents = true;
	@Input() enableTypingIndicator = true;

	private progressEvents: string[] = [
		'start_firstHalf',
		'end_firstHalf',
		'start_secondHalf',
		'finalWhistle',
		'end_secondHalf',
		'start_firstHalfExtra',
		'end_firstHalfExtra',
		'start_secondHalfExtra',
		'end_secondHalfExtra' /* after extra time ends, penaltys hootout or final whistle is displayed */,
		'start_penalty',
		'end_penalty' /* after penalty shoot out, the final whistle is shown, thats enough*/
	];

	constructor() {}
	ngOnChanges(changes: SimpleChanges) {
		if (changes?.['liveEntry'] && this.isEmbedEvent(this.liveEntry) && (this.liveEntry as LiveBlogEntryEditorialEmbedMonterosa)?.detail?.embedPlatform === 'monterosa') {
			this.liveEntry.detail['channelsEnabled'] = { web: true, mobile: true };
		}
	}

	public getEventName(event): 'progress' | 'card' | 'goal' | 'penalty' | 'sub' | 'basic' | 'image' | 'video' | 'var' | 'stats' | 'embed' | 'escapeRate' | 'playerRecord' | 'lineup' {
		if (this.isProgressEvent(event) && this.enableProgressEvents) {
			return 'progress';
		}
		if (this.isCardEvent(event) && this.enableCardEvents) {
			return 'card';
		}
		if (this.isGoalEvent(event) && this.enableGoalEvents) {
			return 'goal';
		}
		if (this.isPenaltyEvent(event) && this.enableGoalEvents) {
			return 'penalty';
		}
		if (this.isSubstitutionEvent(event) && this.enableSubstitutionEvents) {
			return 'sub';
		}
		if (this.isBasicEvent(event) && this.enableBasicEvents) {
			return 'basic';
		}
		if (this.isLineupEvent(event) && this.enableBasicEvents) {
			return 'lineup';
		}
		if (this.isImageEvent(event) && this.enableImageEvents) {
			return 'image';
		}
		if (this.isVideoEvent(event) && this.enableVideoEvents) {
			return 'video';
		}
		if (this.isVarEvent(event) && this.enableVarEvents) {
			return 'var';
		}
		if (this.isStatsEvent(event) && this.enableStatsEvents) {
			return 'stats';
		}
		if (this.isEmbedEvent(event) && this.enableEmbedEvents) {
			return 'embed';
		}
		if (this.isPlayerRecordEvent(event) && this.enableStatsEvents) {
			return 'playerRecord';
		}
	}

	/**
	 *
	 * @param event
	 * @returns {boolean}
	 */
	isProgressEvent(event) {
		return this.progressEvents.includes(event.entryType);
	}

	/**
	 *
	 * @param event
	 * @returns {boolean}
	 */
	isCardEvent(event) {
		return ['yellowCard', 'yellowRedCard', 'redCard'].includes(event.entryType);
	}

	/**
	 *
	 * @param {LiveBlogAutoEvents} event
	 * @returns {boolean}
	 */
	isGoalEvent(event: LiveBlogAutoEvents) {
		return ['goal', 'ownGoal'].includes(event.entryType);
	}

	/**
	 *
	 * @param {LiveBlogAutoEvents} event
	 * @returns {boolean}
	 */
	isPenaltyEvent(event: LiveBlogAutoEvents) {
		return ['penalty'].includes(event.entryType);
	}

	/**
	 *
	 * @param {LiveBlogAutoEvents} event
	 * @returns {boolean}
	 */
	isSubstitutionEvent(event: LiveBlogAutoEvents) {
		return event.entryType === 'sub';
	}

	/**
	 * @param event
	 * @returns {boolean}
	 */
	isBasicEvent(event) {
		return event.entryType === 'freetext';
	}

	/**
	 *
	 * @param event
	 * @returns {boolean}
	 */
	isImageEvent(event) {
		return event.entryType === 'image';
	}

	/**
	 *
	 * @param event
	 * @returns {boolean}
	 */
	isVideoEvent(event) {
		return event.entryType === 'video';
	}

	/**
	 *
	 * @param event
	 * @returns {boolean}
	 */
	isVarEvent(event) {
		return event.entryType === 'VideoAssistEvent' || event.entryType === 'var' || event.entryType === 'videoAssistant';
	}

	/**
	 *
	 * @param event
	 * @returns {boolean}
	 */
	isStatsEvent(event) {
		return event.entryType === 'stats';
	}

	/**
	 *
	 * @param event
	 * @returns {boolean}
	 */
	isEmbedEvent(event) {
		return event.entryType === 'embed';
	}

	/**
	 *
	 * @param event
	 * @returns {boolean}
	 */
	isPlayerRecordEvent(event) {
		return event.entryType === 'playerRecord';
	}

	/**
	 *
	 * @param event
	 * @returns {boolean}
	 */
	isLineupEvent(event) {
		return event.entryType === 'lineup';
	}
}
