<div class="event videoEvent side_{{ side }}">
	<div class="player">
		<dfl-jw-player class="liveEntryVideo" *ngIf="liveEntry.detail && liveEntry.detail.videoId" [video]="video" [showHeadline]="false" [showDescription]="false" [tracking]="false" [ads]="isWebview === true ? false : true" [autoplayNextVideo]="false"></dfl-jw-player>
		<div class="play-time" *ngIf="liveEntry?.detail?.duration && liveEntry?.detail?.duration > 0">{{liveEntry?.detail?.duration | videoduration}}</div>
	</div>
	<div class="body" [ngClass]="{ 'setLtr': isLtr }">
		<div class="playtime">
			<dfl-simple-icon icon="camera-professional-play-symbol" force="light"></dfl-simple-icon>
		</div>
		<div class="headline">
			<livetickerevent-score [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" *ngIf="conference" textColor="text_white"></livetickerevent-score>
			<livetickerevent-headline *ngIf="liveEntry?.detail?.headline" [liveEntry]="liveEntry" [clubData]="clubData" [conference]="conference" textColor="text_white"></livetickerevent-headline>
			<span class="text" *ngIf="liveEntry?.enableHtml === true && liveEntry.detail?.text" [ngClass]="{'no-headline': !liveEntry?.detail?.headline}" [innerHTML]="isWebview ? (liveEntry.detail.text | striphtml:true:'external' | safeHtml) : (liveEntry.detail.text | safeHtml)"></span>
			<span class="text" *ngIf="!(liveEntry?.enableHtml === true) && liveEntry.detail?.text">{{ liveEntry.detail.text }}</span>
		</div>
	</div>
</div>
