import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { getNodgeColor, getSide } from '@nx-bundesliga/bundesliga-com/framework/common';
import { LiveBlogAutoEvents, LiveBlogClubs, LivetickerSides } from '@nx-bundesliga/models';
import { liveblogPostAnimation } from '../../../animations/animations';

@Component({
	selector: 'livetickerevent-basic',
	templateUrl: './livetickerevent-basic.component.html',
	styleUrls: ['../../../style/liveticker.common.scss', './livetickerevent-basic.component.scss'],
	animations: [liveblogPostAnimation('.head, .body livetickerevent-score, .body livetickerevent-headline, .body .detailText')]
})
export class LivetickereventBasicComponent implements OnChanges {
	@HostBinding('@liveblogPostAnimation') liveblogPostAnimation;
	@Input() liveEntry: LiveBlogAutoEvents;
	@Input() clubData: LiveBlogClubs;
	@Input() conference = false;
	@Input() isWebview = false;
	@Input() allowHtml = true;

	// delete after STS data for older seasons has been pushed
	@Input() isLtr?: boolean;

	public side: LivetickerSides;
	public nodgeColor: string;

	constructor() {}

	ngOnChanges() {
		this.side = getSide(this.liveEntry) as LivetickerSides;
		this.nodgeColor = this.liveEntry.playtime && this.liveEntry.playtime.minute === 0 ? 'transparent' : getNodgeColor(this.side, this.clubData);
	}
}
