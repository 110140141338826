import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { EditorialVideo, LiveBlogClubs, LiveBlogEntryEditorialVideo, LivetickerSides } from '@nx-bundesliga/models';
import { getSide } from '@nx-bundesliga/bundesliga-com/framework/common';
import { liveblogPostAnimation } from '../../../animations/animations';

@Component({
	selector: 'livetickerevent-video',
	templateUrl: './livetickerevent-video.component.html',
	styleUrls: ['../../../style/liveticker.common.scss', './livetickerevent-video.component.scss'],
	animations: [liveblogPostAnimation('.body .playtime, .body .headline livetickerevent-score .body .headline livetickerevent-headline, .body .headline .text'), trigger('liveblogPostImageAnimation', [transition(':enter', [style({ opacity: 0 }), animate('250ms ease-in', style({ opacity: 1 }))])])]
})
export class LivetickereventVideoComponent implements OnChanges {
	@HostBinding('@liveblogPostAnimation') liveblogPostAnimation;
	@Input() liveEntry: LiveBlogEntryEditorialVideo;
	@Input() clubData: LiveBlogClubs;
	@Input() conference = false;
	@Input() isWebview = false;

	// delete after STS data for older seasons has been pushed
	@Input() isLtr?: boolean;

	public side: LivetickerSides;
	public video: EditorialVideo;

	constructor() {}

	ngOnChanges() {
		this.side = getSide(this.liveEntry) as LivetickerSides;
		if (this.liveEntry.detail && this.liveEntry.detail.videoId) {
			this.video = {
				type: 'video',
				videoId: this.liveEntry.detail.videoId,
				headline: this.liveEntry.detail.headline ? this.liveEntry.detail.headline : '',
				text: this.liveEntry.detail.text ? this.liveEntry.detail.text : ''
			};
		}
	}
}
