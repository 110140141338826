import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Asset, assetDimensions, AssetDimensions, AssetServicePipe } from '@nx-bundesliga/bundesmaster/core';
import { DialogSelectMediaComponent } from '@nx-bundesliga/bundesmaster/feature-media-library';
import { BundesmasterUiImageCropperDialogComponent } from '@nx-bundesliga/bundesmaster/ui/image-cropper-dialog';
import { BundesligaMatchStates, LiveBlogClubs, LiveBlogEntryEditorialEmbedMonterosa, LivetickerSides, Playtime } from '@nx-bundesliga/models';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { MonterosaSelectComponent } from '@nx-bundesliga/shared/ui/dialogs/monterosa-select';
import idx from 'idx';
import { BehaviorSubject, of, switchMap, timer } from 'rxjs';
import { debounce, map, mapTo } from 'rxjs/operators';
import * as _moment from 'moment';
const moment = _moment;

@Component({
	selector: 'bl-monterosa-event-input',
	templateUrl: './bl-monterosa-event-input.component.html',
	styleUrls: ['./bl-monterosa-event-input.component.scss', '../liveticker-events.common.scss']
})
export class BlMonterosaEventInputComponent implements OnInit {
	@Input() liveEntry: LiveBlogEntryEditorialEmbedMonterosa & { order?: any; disableCrosspost?: boolean };
	@Input() clubData: LiveBlogClubs;
	@Input() minuteOfPlay: Playtime;
	@Input() matchStatus: BundesligaMatchStates;
	@Input() language: string;
	@Input() competitionId: string;
	@Input() seasonId: string;
	@Input() matchdayId: string;
	@Input() matchId: string;
	@Input() headline: string;
	@Input() textBody: string;
	@Input() side: LivetickerSides;
	@Input() embedId: string;
	@Input() orderType = 'auto';
	@Input() orderValue = 'auto';
	@Input() editMode = false;
	@Input() customMatch = false;
	public previewEnabled$: any;
	public previewEntry: LiveBlogEntryEditorialEmbedMonterosa & { order?: any; disableCrosspost?: boolean };
	@Input() tributeOptions: any;
	constructor(private config: ConfigService, public dialog: MatDialog, private assetPipe: AssetServicePipe) {}

	public update() {
		this.previewEntry = Object.assign({}, this.liveEntry, { detail: { ...this.liveEntry.detail, channelsEnabled: { web: true, mobile: true } } });
	}
	public updatePlaytime() {
		if (this.editMode === true && (this.liveEntry.playtime.minute !== this.minuteOfPlay.minute || this.liveEntry.playtime.injuryTime !== this.minuteOfPlay.injuryTime)) {
			if (this.orderType === 'keep') {
				this.orderType = 'auto';
			}
			this.updateOrderType();
		}
	}

	public updateOrderType() {
		switch (this.orderType) {
			case 'auto':
				this.orderValue = 'auto';
				break;
			case 'keep':
				this.orderValue = 'keep';
				break;
			case 'parent':
				this.orderValue = '';
				break;
			case 'child':
				this.orderValue = '';
				break;
			case 'number':
				this.orderValue = '';
				break;
			default:
				this.orderValue = '';
		}
		this.updateOrderValue();
	}

	public updateOrderValue() {
		let prependedText = '';
		if (this.orderType === 'parent' || this.orderType === 'child') {
			prependedText = this.orderType;
		}
		this.liveEntry.order = prependedText + this.orderValue;
	}

	ngOnInit() {
		this.liveEntry = {
			...this.liveEntry,
			entryType: 'embed',
			detail: {
				headline: this.headline || '',
				text: this.textBody || '',
				eventId: idx(this.liveEntry, (_) => _.detail.eventId) || '',
				projectId: idx(this.liveEntry, (_) => _.detail.projectId) || this.config.getSettings('monterosa.projectId', ''),
				channelsEnabled: {
					web: idx(this.liveEntry, (_) => _.detail.channelsEnabled?.web) ?? false,
					mobile: idx(this.liveEntry, (_) => _.detail.channelsEnabled?.mobile) ?? true
				},
				placeholder: {
					target: idx(this.liveEntry, (_) => _.detail.placeholder?.target) || '',
					image: {
						liveticker: {
							url: idx(this.liveEntry, (_) => _.detail.placeholder?.image?.liveticker?.url) || ''
						}
					}
				},
				// monterosa comes from bundesstreaker
				embedPlatform: 'monterosa'
			},
			eventId: '',
			entryDate: idx(this.liveEntry, (_) => _.entryDate) || '' + moment().format('YYYY-MM-DDTHH:mm:ssZZ'),
			matchSection: this.matchStatus || 'PRE_MATCH',
			side: this.side,
			playtime: {
				minute: this.minuteOfPlay.minute || 0,
				injuryTime: this.minuteOfPlay.injuryTime || 0
			},
			conference: this.customMatch === true ? false : idx(this.liveEntry, (_) => _.conference) || false,
			enableHtml: idx(this.liveEntry, (_) => _.enableHtml) || false,
			disableCrosspost: this.editMode || idx(this.liveEntry, (_) => _.disableCrosspost) || false,
			hidden: idx(this.liveEntry, (_) => _.hidden) || false
		};
		this.initializeOrderDefault();
		this.update();
		this.previewEnabled$ = new BehaviorSubject<boolean>(false);
		this.previewEnabled$ = this.previewEnabled$.pipe(debounce((val) => (val === false ? of(false) : timer(3000).pipe(mapTo(true)))));
		if (((idx(this.previewEntry, (_) => _.detail.eventId) || false) && idx(this.previewEntry, (_) => _.detail.projectId)) || false) {
			this.reloadEmbed();
		}
	}

	initializeOrderDefault() {
		if (this.editMode === true) {
			this.orderType = 'keep';
			this.updateOrderType();
		}
	}

	public getPayload() {
		return this.liveEntry;
	}

	public reloadEmbed() {
		this.previewEnabled$.next(false);
		this.previewEnabled$.next(true);
	}

	openDialog(): void {
		this.dialog
			.open(MonterosaSelectComponent, {
				data: {
					projectId: this.liveEntry?.detail?.projectId ?? null
				}
			})
			.afterClosed()
			.subscribe((data: string) => {
				if (data) {
					this.liveEntry.detail.eventId = data;
					this.update();
					this.reloadEmbed();
				}
			});
	}

	public selectImage() {
		this.dialog
			.open(DialogSelectMediaComponent, {
				data: { hideDimensions: true },
				maxWidth: '90vw',
				maxHeight: '90vh',
				width: '90%'
			})
			.afterClosed()
			.pipe(
				switchMap((imageSelectionResponse: { asset: Asset; dimensions: AssetDimensions[] }) => {
					if (imageSelectionResponse) {
						return this.dialog
							.open(BundesmasterUiImageCropperDialogComponent, {
								data: {
									imageUrl: this.assetPipe.transform(imageSelectionResponse.asset.file),
									targetAspectRatio: assetDimensions['liveticker'].ratio
								},
								maxWidth: '90vw',
								maxHeight: '90vh',
								width: '100%',
								height: 'auto'
							})
							.afterClosed()
							.pipe(
								map((croppedUrl: string) => {
									if (croppedUrl) {
										return { url: croppedUrl, copyright: imageSelectionResponse.asset.copyright };
									}
									return null;
								})
							);
					}
					return of(null);
				})
			)
			.subscribe((response: { url: string; copyright: string }) => {
				if (response) {
					this.liveEntry.detail.placeholder.image.liveticker.url = this.assetPipe.transform(response.url);
					this.update();
					this.reloadEmbed();
				}
				console.log('image selected and cropped', response);
			});
	}

	cropImage() {
		this.dialog
			.open(BundesmasterUiImageCropperDialogComponent, {
				data: {
					imageUrl: this.liveEntry.detail.placeholder.image.liveticker.url,
					targetAspectRatio: assetDimensions['liveticker'].ratio
				},
				maxWidth: '90vw',
				maxHeight: '90vh',
				width: '100%',
				height: 'auto'
			})
			.afterClosed()
			.subscribe((response: string) => {
				if (response) {
					this.liveEntry.detail.placeholder.image.liveticker.url = response;
					this.update();
					this.reloadEmbed();
				}
				console.log('image cropped', response);
			});
	}
}
