<div class="container" [id]="instanceId"></div>
@if(!showMonterosa ) {
<a [href]="monterosa?.placeholder?.target ?? isNewsPage ?  ('MONTEROSA.PLACEHOLDER.TARGET.ARTICLE' | translate) :('MONTEROSA.PLACEHOLDER.TARGET.LIVETICKER' | translate)  ">
	<picture>
		<source media="(max-width: 767px)" [srcset]="'MONTEROSA.PLACEHOLDER.IMAGE' | translate | imageService:540" />
		<source media="(min-width: 768px)" [srcset]="'MONTEROSA.PLACEHOLDER.IMAGE' | translate | imageService:1140" />
		<img [ngSrc]="'MONTEROSA.PLACEHOLDER.IMAGE' | translate | imageService:540" [alt]="'MONTEROSA.PLACEHOLDER.ALT' | translate" fill priority style="position: relative" />
	</picture>
</a>
}
